import React, { useState } from 'react';

const UserRightsCSVGenerator = () => {
  const [formData, setFormData] = useState({
    identifierType: 'userEmail',
    identifierValue: '',
    from: '',
    to: '',
    tracksIsrc: '',
    includeAll: false,
    token: '',
  });

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 🚀 Loading state
  const [error, setError] = useState<string | null>(null); // 🚀 Error state

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: (e.target as HTMLInputElement).checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    setError(null); // Reset error on new request

    const dataToSend: any = {
      from: formData.from ? formData.from : undefined,
      to: formData.to ? formData.to : undefined,
      trackIds: formData.tracksIsrc.length
        ? formData.tracksIsrc.split(',')
        : undefined,
      includeAll: formData.includeAll,
      token: formData.token || undefined,
    };

    if (formData.identifierType === 'userId') {
      dataToSend.userId = parseInt(formData.identifierValue, 10) || undefined;
    } else if (formData.identifierType === 'userEmail') {
      dataToSend.userEmail = formData.identifierValue || undefined;
    } else if (formData.identifierType === 'userCoad') {
      dataToSend.userCoad = formData.identifierValue || undefined;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/user-rights/csv`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error('Error downloading CSV. Please try again.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user-rights.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setError((error as Error).message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false); // Re-enable button after request completes (success or fail)
    }
  };

  return (
    <div className="max-w-screen-xl flex justify-center py-4 md:py-12 px-4 mx-auto md:px-6 lg:px-8">
      <div className="mx-auto flex w-full max-w-3xl flex-col space-y-6 rounded bg-white p-6 shadow">
      <h4>User Rights CSV</h4>
      <form onSubmit={handleSubmit}>
        <label>Search By:</label>
        <select name="identifierType" value={formData.identifierType} onChange={handleChange}>
          <option value="userId">User ID</option>
          <option value="userEmail">User Email</option>
          <option value="userCoad">User Coad</option>
        </select>

        <label>Enter {formData.identifierType.replace('user', 'User ')}:</label>
        <input
          type={formData.identifierType === 'userId' ? 'number' : 'text'}
          name="identifierValue"
          value={formData.identifierValue}
          onChange={handleChange}
          placeholder={`Enter ${formData.identifierType.replace('user', 'User ')}`}
        />

        <label>Token:</label>
        <input type="text" name="token" value={formData.token} onChange={handleChange} />

        <a href="#" onClick={(e) => {
          e.preventDefault(); // Prevent page jump
          setShowAdvancedOptions(!showAdvancedOptions);
        }}>
          {showAdvancedOptions ? 'Hide Advanced Options' : 'Show Advanced Options'}
        </a>
        {showAdvancedOptions && (
          <div className="mt-4">
            <label>From Date:</label>
            <input type="date" name="from" value={formData.from} onChange={handleChange} />

            <label>To Date:</label>
            <input type="date" name="to" value={formData.to} onChange={handleChange} />

            <label>Track IDs (comma separated):</label>
            <input
              type="text"
              name="trackIds"
              value={formData.tracksIsrc}
              onChange={handleChange}
              placeholder="e.g., FR96X1910006,BCD4N2100076"
            />

            <label className="flex-row justify-center items-center self-start">
              <input className="mr-2"
                type="checkbox"
                name="includeAll"
                checked={formData.includeAll}
                onChange={handleChange}
              />
              Include All
            </label>
          </div>
        )}

        <hr />
        <button className="button" type="submit" disabled={isLoading}>
          {isLoading ? "Downloading..." : "Download CSV"}
        </button>
        {/* 🚀 Display error message if API request fails */}
        {error && <p className="mt-6 p-3 bg-red-500 text-white rounded">{error}</p>}
      </form>
    </div>
    </div>
  );
};

export default UserRightsCSVGenerator;