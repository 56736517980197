// CardRightsTotal.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import 'dayjs/locale/fr';
import { CountUp } from '../../utils/countUp';
import Squelton from '../../components/Squeleton';
import { useRightsByChannelType } from 'hooks/use-rights';
import Tippy from '@tippyjs/react';
import moment from 'moment';

export function CardRightsTotal() {
  const { rightsByChannelType, isLoadingRightsByChannelType } = useRightsByChannelType();

  return (
    <>
      <div className="w-full">
        <div className="rounded-t bg-white px-6 py-4 shadow">
          <div className="font-display text-xl">Droits en attente de répartition</div>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Mis à jour toutes les 24 heures ou lors de la dernière diffusion d'un des titres monitorés.
          </p>
        </div>
        <div role="list" className="mb-6 overflow-hidden rounded-b shadow">
          <div className="flex justify-between gap-x-6 bg-stream-50 p-6">
            <div className="flex min-w-0 gap-x-4">
              <FontAwesomeIcon
                icon={icon({ name: 'cloud-music', family: 'duotone', style: 'solid' })}
                className="h-6 flex-none text-stream-600"
              />
              <div className="min-w-0 flex-auto">
                  {rightsByChannelType && (
                <>
                    <p className="!pb-0 leading-6 text-stream-900 font-bold">
                  Streams{' '}</p>
                  <p className="!pb-0 mt-1 max-w-2xl text-sm leading-6 text-stream-700">
                    Droits calculés sur les diffusions postérieures au{' '}
                    {moment(rightsByChannelType.streaming.collectPeriodStart).format('DD/MM/YYYY')}
                    <Tippy
                      content="Les diffusions précédentes sont présumées déjà réparties par la Sacem et sont donc inéligibles"
                    >
                      <span className="cursor-help text-primary-500 align-middle">
                        <FontAwesomeIcon
                          icon={icon({ name: 'circle-info', family: 'sharp', style: 'light' })}
                          className="ml-1.5 h-4 w-4 flex-none"
                        />
                      </span>
                    </Tippy>
                  </p>
                </>
                  )}

              </div>
            </div>
            <div className="shrink-0 text-stream-800 sm:flex sm:flex-row sm:items-end">
              {!isLoadingRightsByChannelType && rightsByChannelType ? (
                <span>
                  <CountUp className="font-normal" value={rightsByChannelType.streaming.amount} /> €
                </span>
              ) : (
                <Squelton color="green" />
              )}
            </div>
          </div>
          <div className="flex justify-between gap-x-6 bg-radio-50 p-6">
            <div className="flex min-w-0 gap-x-4">
              <FontAwesomeIcon
                icon={icon({ name: 'radio', family: 'duotone', style: 'solid' })}
                className="h-6 flex-none text-radio-600"
              />
              <div className="min-w-0 flex-auto">
                {rightsByChannelType && (
                  <>
                    <p className="!pb-0 text-radio-900 font-bold">Radios</p>
                    <p className="!pb-0 mt-1 max-w-2xl text-sm leading-6 text-radio-700">
                      Droits calculés sur les diffusions postérieures au{' '}
                      {moment(rightsByChannelType.radio.collectPeriodStart).format('DD/MM/YYYY')}
                    <Tippy
                      content="Les diffusions précédentes sont présumées déjà réparties par la Sacem et sont donc inéligibles"
                    >
                      <span className="cursor-help text-primary-500 align-middle">
                        <FontAwesomeIcon
                          icon={icon({ name: 'circle-info', family: 'sharp', style: 'light' })}
                          className="ml-1.5 h-4 w-4 flex-none"
                        />
                      </span>
                    </Tippy>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="shrink-0 text-radio-800 sm:flex sm:flex-col sm:items-end">
              {!isLoadingRightsByChannelType && rightsByChannelType ? (
                <span>
                  <CountUp className="font-normal" value={rightsByChannelType.radio.amount} /> €
                </span>
              ) : (
                <Squelton color="orange" />
              )}
            </div>
          </div>
          <div className="flex justify-between gap-x-6 bg-tv-50 p-6">
            <div className="flex min-w-0 gap-x-4">
              <FontAwesomeIcon
                icon={icon({ name: 'tv-music', family: 'duotone', style: 'solid' })}
                className="h-6 flex-none text-tv-600"
              />
              <div className="min-w-0 flex-auto">
                  {rightsByChannelType && (
                    <>
                      <p className="!pb-0 text-tv-900 font-bold">TV</p>
                      <p className="!pb-0 mt-1 max-w-2xl text-sm leading-6 text-tv-700">
                        Droits calculés sur les diffusions postérieures au{' '}
                        {moment(rightsByChannelType.tv.collectPeriodStart).format('DD/MM/YYYY')}
                        <Tippy
                          content="Les diffusions précédentes sont présumées déjà réparties par la Sacem et sont donc inéligibles"
                        >
                      <span className="cursor-help text-primary-500 align-middle">
                        <FontAwesomeIcon
                          icon={icon({ name: 'circle-info', family: 'sharp', style: 'light' })}
                          className="ml-1.5 h-4 w-4 flex-none"
                        />
                      </span>
                        </Tippy>
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div className="shrink-0 text-tv-800 sm:flex sm:flex-col sm:items-end">
              {!isLoadingRightsByChannelType && rightsByChannelType ? (
                <span>
                  <CountUp className="font-normal" value={rightsByChannelType.tv.amount} /> €
                </span>
              ) : (
                <Squelton color="blue" />
              )}
            </div>
          </div>
          <div className="flex justify-between gap-x-6 bg-white p-6">
            <div className="flex min-w-0 gap-x-4">
              <FontAwesomeIcon
                icon={icon({ name: 'chart-mixed-up-circle-dollar', family: 'sharp', style: 'light' })}
                className="h-6 flex-none"
              />
              <div className="min-w-0 flex-auto">
                <p className="!pb-0 leading-6">TOTAL</p>
              </div>
            </div>
            <div className="shrink-0 font-bold sm:flex sm:flex-col sm:items-end">
              {!isLoadingRightsByChannelType && rightsByChannelType ? (
                <span>
                  <CountUp value={rightsByChannelType.total} /> €
                </span>
              ) : (
                <Squelton color="gray" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
