import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import useUserInfo from '../../hooks/use-user-info';
import { Link } from 'react-router-dom';
import { CountUp } from '../../utils/countUp';
import { activeEmptyState, isLoadingFake } from '../../App';
import Squelton from '../../components/Squeleton';
import useAvailableCashOutAmount from 'hooks/use-available-cash-out-amount';
import useRights from 'hooks/use-rights';
import Squeleton from '../../components/Squeleton';
import Alert from '../../components/Alert'

export function CardRightsSummary() {
  const {
    userInfo: { registerTs },
  } = useUserInfo();

  const { rights, isLoadingRights } = useRights();

  const { totalCashedOut, amountToBeCashedOut, isLoadingAmountToBeCashedOut } = useAvailableCashOutAmount();

  const isLoading = isLoadingRights || isLoadingAmountToBeCashedOut || isLoadingFake;

  const startDate = dayjs(registerTs);
  const daysElapsed = dayjs().diff(startDate, 'day');

  const getPreviousDays = (numDays: number) => {
    const daysArray = [];
    for (let i = 0; i < numDays; i++) {
      daysArray.push(dayjs().subtract(i + 1, 'day'));
    }
    return daysArray;
  };

  const previousDays = getPreviousDays(5);

  return (
    <>
      <ul role="list" className="divide-y divide-gray-200 rounded bg-white px-6 shadow">
        {/*<li className="flex justify-between gap-x-6 py-6">*/}
        {/*  <div className="flex min-w-0 gap-x-4">*/}
        {/*    <FontAwesomeIcon*/}
        {/*      icon={icon({ name: 'chart-mixed-up-circle-dollar', family: 'sharp', style: 'light' })}*/}
        {/*      className="h-12 w-12 flex-none"*/}
        {/*    />*/}
        {/*    <div className="min-w-0 flex-auto">*/}
        {/*      <p className="!pb-0 font-bold leading-6 text-gray-900">*/}
        {/*        Droits générés sur {daysElapsed > 1 ? `les ${daysElapsed}` : 'le'} dernier{daysElapsed > 1 ? 's' : ''}{' '}*/}
        {/*        jour{daysElapsed > 1 ? 's' : ''}*/}
        {/*      </p>*/}
        {/*      <p className="truncate text-xs leading-5 text-gray-500">*/}
        {/*        Montant total généré depuis votre inscription sur RightsNow!.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="shrink-0 sm:flex sm:flex-col sm:items-end">*/}
        {/*    {!isLoading ? <CountUp value={rights?.total || 0} type="currency" /> : <Squelton />}*/}
        {/*    <Link to="/rights-historic" className="text-xs leading-5">*/}
        {/*      Voir le détail*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*</li>*/}
        {/*<li className="flex justify-between gap-x-6 py-6">*/}
        {/*  <div className="flex min-w-0 gap-x-4">*/}
        {/*    <FontAwesomeIcon*/}
        {/*      icon={icon({ name: 'piggy-bank', family: 'sharp', style: 'light' })}*/}
        {/*      className="h-12 w-12 flex-none"*/}
        {/*    />*/}
        {/*    <div className="min-w-0 flex-auto">*/}
        {/*      <p className="!pb-0 font-bold leading-6 text-gray-900">Avances déjà demandées</p>*/}
        {/*      <p className="truncate text-xs leading-5 text-gray-500">Cumul des avances demandées et réalisées.</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="shrink-0 sm:flex sm:flex-col sm:items-end">*/}
        {/*    {!isLoading ? <CountUp value={totalCashedOut || 0} type="currency" /> : <Squelton />}*/}
        {/*    <Link to="/cash-out-historic" className="text-xs leading-5">*/}
        {/*      Voir l'historique*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*</li>*/}
        <li className="flex justify-between gap-x-6 py-6">
          <div className="flex min-w-0 gap-x-4">
            <FontAwesomeIcon
              icon={icon({ name: 'hand-holding-dollar', family: 'sharp', style: 'light' })}
              className="h-12 w-12 flex-none text-green-700"
            />
            <div className="min-w-0 flex-auto">
              <p className="!pb-0 font-semibold leading-6 text-green-700">Avance disponible</p>
              <p className="text-xs leading-5 text-green-500">
                Montant de l’avance que vous pouvez demander à RightsNow!.<br/>Cette avance sera soumise à l'acceptation de la Sacem sous réserve de l'absence de créanciers déjà effectifs.
              </p>
            </div>
          </div>
          <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
            {!activeEmptyState && !isLoadingAmountToBeCashedOut && amountToBeCashedOut !== undefined ? (
              <>
                {amountToBeCashedOut > 10 && (
                  <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <CountUp value={amountToBeCashedOut} type="currency" className="text-green" />{' '}
                  </div>
                )}
              </>
            ) : !isLoadingAmountToBeCashedOut && amountToBeCashedOut === undefined ? (
              <p className="text-gray-500">Montant non disponible</p>
            ) : (
              <Squeleton color="white" />
            )}
          </div>
        </li>
        <li className="-mx-6">
          <Alert color="orange" title="IMPORTANT" className="rounded-none rounded-b">
            Cette avance sera <strong>directement remboursée par la Sacem à RightsNow!</strong> grâce à votre(vos)
            prochaine(s) répartition(s). En aucun cas vous n'êtes redevable directement à RightsNow!.
          </Alert>
        </li>
      </ul>
    </>
  );
}
