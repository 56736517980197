import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import useUserInfo from '../../hooks/use-user-info';
import { Link, useLocation } from 'react-router-dom';
import CashOutStatusLegend from '../../components/CashOutStatusLegend';
import { cashOutStatusDetails } from 'shared-types/cash-out-status'; // Importer les détails des statuts
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Badge from '../../components/Badge';
import Alert from '../../components/Alert';
import { activeEmptyState, ErrorState, isLoadingFake } from '../../App';
import useCashOut from 'hooks/use-cash-out';
import { CashOut } from 'shared-types/cash-out';
import EmptyState from 'components/EmptyState';
import Squeleton from 'components/Squeleton';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import useRights from 'hooks/use-rights';
import Loader from '../../components/Loader';

export function CashOutHistoricPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const from = query.get('from');
  const cashOutRequested = from === 'cash-out-request';
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { rights, isLoadingRights, isErrorRights } = useRights();
  const { cashOuts, isLoadingCashOuts, isErrorCashOuts } = useCashOut();
  const [erroneousCashOut, setErroneousCashOut] = useState<CashOut | undefined>();

  const handleOpenModal = (cashOut: CashOut) => {
    setErroneousCashOut(cashOut);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setErroneousCashOut(undefined);
    setIsModalOpen(false);
  };
  const {
    userInfo: { email },
  } = useUserInfo();

  const errorState = isErrorCashOuts || ErrorState;
  const isCashOutPossible = rights && rights.total > 0;
  const loadingState = isLoadingCashOuts || isLoadingRights || isLoadingFake;
  const emptyState = (cashOuts && cashOuts.length === 0) || activeEmptyState;

  if (loadingState) {
    return <Loader type="vertical" />;
  }

  if (errorState) {
    return <Alert color="red">Erreur lors du chargement des données</Alert>;
  }

  if (emptyState) {
    if (isCashOutPossible) {
      return (
        <EmptyState title="Aucune avance demandée">
          {/*<Link to="/cash-out-request" className="button mt-6">*/}
          {/*  <p>Demander ma première avance</p>*/}
          {/*</Link>*/}
        </EmptyState>
      );
    } else {
      return (
        <EmptyState title="Données récoltées insufisantes">
          <p>Pour que vous puissiez demander une avance, il faut que vos titres génèrent suffisamment de diffusions.</p>
        </EmptyState>
      );
    }
  }

  if (cashOuts && cashOuts.length > 0) {
    return (
      <>
        {cashOutRequested && (
          <Alert color="green" title="MERCI !">
            <p>
              Votre demande d'avance a bien été prise en compte.
              <br />
              Elle sera traitée immédiatement et disponible sur votre compte sous 72h ouvrées (sauf si problème sur la
              demande).
            </p>
          </Alert>
        )}
        <div className="mb-6 flex items-center justify-between">
          <h1 className="h4 mb-0 text-gray-800">Mes demandes d'avance</h1>
          {/*<Tippy content="Vous ne pouvez faire qu'une demande d'avance pour le moment">*/}
          {/*  <span>*/}
          {/*    <Link className={cx('button', { disabled: cashOuts.length > 0 })} to="/cash-out-request">*/}
          {/*      <FontAwesomeIcon icon={icon({ name: 'plus', family: 'sharp', style: 'light' })} className="md:mr-2" />*/}
          {/*      <span className="hidden md:inline-block">Demander une avance</span>*/}
          {/*    </Link>*/}
          {/*  </span>*/}
          {/*</Tippy>*/}
        </div>
        <div className="w-full overflow-hidden rounded bg-white px-8 py-4 shadow-xl">
          <div className="flow-root">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                {!loadingState ? (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="w-1 py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
                          ID
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-right text-sm text-gray-900">
                          Date de la demande
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-right text-sm text-gray-900">
                          Montant
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
                          <div className="flex items-center justify-between">
                            Statut <CashOutStatusLegend />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {cashOuts.map((cashOut) => {
                        const statusDetail = cashOutStatusDetails.find(
                          (statusDetails) => statusDetails.status === cashOut.status,
                        );
                        return (
                          <tr key={cashOut.cashOutId}>
                            <td className="w-1 whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-0">
                              <span className="select-none text-gray-400">#</span>
                              <span className="select-text text-gray-900">{cashOut.cashOutId}</span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-right text-sm">
                              <div className="text-gray-900">{moment(cashOut.requestedAt).format('DD/MM/YYYY')}</div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-right text-sm">{cashOut.amount} €</td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm">
                              <Badge
                                content={statusDetail?.name ?? ''}
                                color={statusDetail?.color ?? 'gray'}
                                icon={statusDetail?.icon}
                              />
                              {cashOut.status === 'ERROR' && (
                                <>
                                  <a onClick={() => handleOpenModal(cashOut)} className="ml-2 underline">
                                    Pourquoi ?
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <Squeleton type="table" col={4} row={4} />
                )}
              </div>
            </div>
          </div>
        </div>
        <ProblemModal isOpen={isModalOpen} onClose={handleCloseModal} erroneousCashOut={erroneousCashOut} />
      </>
    );
  }

  return <></>;
}

function ProblemModal({
  isOpen,
  onClose,
  erroneousCashOut,
}: {
  isOpen: boolean;
  onClose: () => void;
  erroneousCashOut?: CashOut;
}) {
  const reason = erroneousCashOut?.errorReason;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative inline-block translate-y-0 transform overflow-hidden rounded bg-white text-left align-bottom opacity-100 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:scale-100 sm:align-middle">
                <div className="bg-white px-4 py-4 sm:flex sm:items-start sm:p-6">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon
                      icon={icon({ name: 'circle-question', family: 'sharp', style: 'light' })}
                      className="text-blue"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-xl leading-loose">
                      Raison(s) du problème
                    </Dialog.Title>
                    <div className="mt-6">
                      {/*Des informations détaillées sur la raison du problème seront affichées ici.*/}
                      {reason && reason}
                      {!reason && (
                        <>
                          <p>Une erreur est survenue lors du traitement de votre demande.</p>
                          <p>Merci de nous <a href="https://www.rightsnow.fr/support/" target="_blank" className="underline">contacter</a>.</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-100 px-6 py-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="button inline-flex w-full justify-center sm:w-auto"
                    onClick={onClose}
                  >
                    Fermer
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
