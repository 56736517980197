import { useQuery } from 'react-query';
import { TimeRange, TimeRangeInterval, UserInfoDTO } from 'shared-types';
import api from 'services/api';
import { getTimeRangeInterval } from 'features/dashboard/BroadcastChart';

export default function useRights(timeRange?: TimeRange) {
  const from = getTimeRangeInterval(timeRange || 'all_time').lowerBound?.unix();
  const to = getTimeRangeInterval(timeRange || 'all_time').upperBound.unix();
  const {
    data: rights,
    isLoading: isLoadingRights,
    isError: isErrorRights,
    ...rest
  } = useQuery({
    queryKey: ['/api/rights', timeRange],
    queryFn: () => api.fetchRights({ from, to }),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  return {
    rights: rights && { ...rights, total: rights.streams + rights.radio + rights.tv },
    isLoadingRights,
    isErrorRights,
    ...rest,
  };
}

export function useRightsByChannelType() {
  const {
    data: rightsByChannelType,
    isLoading: isLoadingRightsByChannelType,
    isError: isErrorRightsByChannelType,
    ...rest
  } = useQuery({
    queryKey: ['/api/rights-by-channel-type'],
    queryFn: () => api.fetchRightsByChannelType(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  return {
    rightsByChannelType: rightsByChannelType && {
      ...rightsByChannelType,
      total: rightsByChannelType.streaming.amount + rightsByChannelType.radio.amount + rightsByChannelType.tv.amount,
    },
    isLoadingRightsByChannelType,
    isErrorRightsByChannelType,
    ...rest,
  };
}

export function useMonthlyRights(options?: { month?: number; year?: number }) {
  const {
    data: monthlyRights,
    isLoading: isLoadingMonthlyRights,
    isError: isErrorMonthlyRights,
    ...rest
  } = useQuery({
    queryKey: ['/api/rights/monthly', { month: options?.month || 0, year: options?.year || 0 }],
    queryFn: () => api.fetchMonthlyRights(options),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  return {
    monthlyRights,
    isLoadingMonthlyRights,
    isErrorMonthlyRights,
    ...rest,
  };
}
